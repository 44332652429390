<template>
    <div class="examinations">

    </div>
</template>

<script>
    export default {
        name: "AllExamIndividual",
        props: {
            enrollId: {
                required: true,
                type: Number
            }
        },
        data() {
            return {
                dataPrepared: {}
            }
        },
        computed: {},
        mounted() {
            // console.log(this.enrollId);
            this.fetchAllExamRecords();
        },
        methods: {
            fetchAllExamRecords() {
                this.$rest.get('/api/marksheet-individual/all', {params: {enrollId: this.enrollId}}).then(({data}) => {
                    // console.log(data.data);
                    if (this.dataPrepared.hasOwnProperty(data.data.exam_id)) {

                    } else {

                    }

                });
            }
        }
    }
</script>

<style scoped>

</style>